<template>
  <a-card :bordered="false" class="card-area">
    <div class="search">
      <!-- 搜索区域 -->
      <div class="items" style="flex: none">
        <div class="one-item" style="width: 100px">
          <a-input
              class="input"
              v-model="queryParams.postId"
              placeholder="帖子ID"
              allowClear
          />
        </div>
        <div class="one-item" style="width: 100px">
          <a-select
              class="input"
              v-model="queryParams.status"
              placeholder="状态"
              @change="search(1)"
              allowClear
          >
            <a-select-option v-for="r in dict[statusDictName]" :key="r.value.toString()">{{ r.label }}
            </a-select-option>
          </a-select>
        </div>
        <div class="one-item" style="width: 100px">
          <a-select
              class="input"
              v-model="queryParams.mode"
              placeholder="类型"
              @change="search(1)"
              allowClear
          >
            <a-select-option v-for="r in dict['commentMode']" :key="r.value.toString()">{{ r.label }}
            </a-select-option>
          </a-select>
        </div>

        <div class="one-item" style="width: 120px">
          <a-input
              class="input"
              v-model="queryParams.username"
              placeholder="用户名"
              allowClear
          />
        </div>

        <div class="one-item" style="width:200px">
          <a-input
              class="input"
              v-model="queryParams.content"
              placeholder="内容"
              allowClear
          />
        </div>

        <div class="one-item" style="width: 150px">
          <a-select
              class="input"
              v-model="queryParams.userAccountType"
              placeholder="用户类型"
              @change="search(1)"
              allowClear
          >
            <a-select-option v-for="r in dict['userAccountType']" :key="r.value.toString()">{{ r.label }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
        <a-button style="margin-left: 8px" @click="reset">重置</a-button>
      </div>
    </div>

    <a-table
        ref="TableInfo"
        size="small"
        :bordered="true"
        :columns="columns"
        rowKey="id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ y: 500 }"
        @expand="expand"
        :expandedRowKeys="expandedRowKeys"
    >
      <template
          slot="expandedRowRender"
          slot-scope="record"
          style="margin: 0"
      >
        <comment-expan-tab ref="expanTab"/>
      </template>
      <template slot="images" slot-scope="text, record">
        <div v-if="text.avatar" class="img-list">
          <el-image
              v-for="item in (text.avatar || '').split(',')"
              :key="item"
              style="width: 40px; height: 40px"
              :src="item | fullImgPath"
              fit="contain"
              :preview-src-list="(text.avatar || '').split(',')"
          />
        </div>
      </template>
      <template slot="images2" slot-scope="text, record">
        <div v-if="record.media" class="img-list">
          <el-image
              v-for="item in JSON.parse(record.media )"
              :key="item"
              style="width: 40px; height: 40px"
              :src="item | fullImgPath"
              fit="contain"
              :preview-src-list="JSON.parse(record.media )"
          />
        </div>
      </template>
      <template slot="childComments" slot-scope="text, record">
        <div
            class="textClick"
            :style="{ color: textColor }"
            @click="expand(!showExpand, text)"
        >
          {{ text.childComments || 0 }}
        </div>
      </template>

      <template slot="prizeCount" slot-scope="text, record">
        <div
            class="textClick"
            :style="{ color: textColor }"
            @click="changePrizeCount(record)"
        >
          {{ record.prizeCount || 0 }}
        </div>
      </template>
      <template slot="mode" slot-scope="text, record">
        <a-tag :color="record.mode | dictName('commentMode', 'color')" style="cursor: pointer">
          <span>{{ record.mode| dictName("commentMode") }}</span>
        </a-tag>
      </template>
      <template slot="userAccountType" slot-scope="text, record">
        <a-tag :color="record.userAccountType | dictName('userAccountType', 'color')" style="cursor: pointer">
          <span>{{ record.userAccountType| dictName("userAccountType") }}</span>
        </a-tag>
      </template>
      <template slot="content" slot-scope="text, record">
        <a-tooltip>
          <template slot="title"> {{ text.content }}</template>
          <div>
            {{
              text.content.length > 30
                  ? text.content.slice(0, 50) + "..."
                  : text.content
            }}
          </div>
        </a-tooltip>
      </template>
      <template slot="postId" slot-scope="text, record">
        <a href="javascript:void(0)" @click="openPostModal(record)"
           style="text-decoration: underline;color:rgb(24, 144, 255)">
          {{ text }}
        </a>
      </template>
      <template slot="operation" slot-scope="text, record">
        <div style="display: flex;justify-content: space-between">
          <a-button
              type="primary"
              size="small"
              @click="modifyPrizeTask(record)"
          >
            点赞
          </a-button>
          <a-button
              type="success"
              size="small"
              @click="modifyCommentTask(record)"
          >
            评论
          </a-button>
          <a-button
              type="danger"
              size="small"
              @click="onDelete(record)"
          >
            删除
          </a-button>
        </div>
      </template>
    </a-table>

    <modify-comment-modal ref="commentModal" @success="editSuccess"/>
    <modify-post-modal ref="modifyPostModal"/>
    <post-prize-task-modal ref="postPrizeTaskModal"/>
    <post-comment-task-modal ref="postCommentTaskModal"/>
  </a-card>
</template>
<script>
import {ListMixin} from "@/mixin/list-mixin";
import ModifyCommentModal from "./ModifyCommentModal.vue";
import CommentExpanTab from "./CommentExpanTab.vue";
import {DICT} from "@/utils/dict";
import {MessageBox} from "element-ui";
import ModifyPostModal from "../postlist/modifyModal.vue";
import PostPrizeTaskModal from "../postlist/postPrizeTaskModal";
import PostCommentTaskModal from "../postlist/postCommentTaskModal";

const clipboardy = require('clipboardy');

export default {
  name: "CommentList",
  mixins: [ListMixin],
  components: {
    ModifyCommentModal, ModifyPostModal, CommentExpanTab, PostPrizeTaskModal, PostCommentTaskModal
  },
  data() {
    return {
      listGetUrl: "comment/listByAdmin",
      listMethod: "postJson",
      showExpand: false,
      showLoading: false,
      expandedRowKeys: [],
    };
  },
  computed: {
    textColor() {
      return this.$store.state.setting.color;
    },
    statusDictName() {
      return 'postStatus';
    },
    showStatusList() {
      return DICT['isShow']
    },
    columns() {
      // let {sortedInfo, filteredInfo} = this;
      // sortedInfo = sortedInfo || {};
      // filteredInfo = filteredInfo || {};
      let colList = [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "id",
        },
        {
          title: "帖子ID",
          width: 100,
          align: "center",
          dataIndex: "postId",
          scopedSlots: {customRender: "postId"},
        },
        {
          title: "头像",
          width: 80,
          align: "center",
          scopedSlots: {customRender: "images"},
        },
        {
          title: "发布用户名",
          width: 200,
          align: "center",
          dataIndex: "username",
        },
        {
          title: "用户类型",
          width: 100,
          align: "center",
          dataIndex: "userAccountType",
          scopedSlots: {customRender: "userAccountType"},
        },
        {
          title: "类型",
          width: 100,
          align: "center",
          dataIndex: "mode",
          scopedSlots: {customRender: "mode"},
        },
        {
          title: "内容",
          align: "center",
          width: 300,
          // dataIndex: "content",
          scopedSlots: {customRender: "content"},
        },
        // {
        //   title: "公司/律所名称",
        //   width: 200,
        //   align: "center",
        //   dataIndex: "company",
        // },
        // {
        //   title: "图片",
        //   align: "center",
        //   width: 150,
        //   // dataIndex: "content",
        //   scopedSlots: { customRender: "images2" },
        // },
        {
          title: "点赞数",
          width: 100,
          align: "center",
          dataIndex: "prizeCount",
          scopedSlots: {customRender: "prizeCount"},
        },
        {
          title: "评论回复数",
          width: 100,
          align: "center",
          // dataIndex: "childComments",
          scopedSlots: {customRender: "childComments"},
        },
        {
          title: "创建时间",
          width: 160,
          align: "center",
          dataIndex: "createTime",
        },
        {
          title: "操作",
          align: "center",
          width: 180,
          dataIndex: "operation",
          // fixed: "right",
          scopedSlots: {customRender: "operation"},
        },
        {}
      ];
      return colList;
    }
  },

  mounted() {
    this.search(1);
  },
  methods: {
    // 反馈与投诉
    feedBackModal(record) {
      if (record.feedBackCount == 0 || !record.feedBackCount) {
        this.$message.error("该帖子暂无反馈与投诉");
        return;
      }
      this.$refs.feedBackModal.showModal(record);
    },
    // 评论数
    opencommentModal(record) {
      if (record.commentCount == 0 || !record.commentCount) {
        this.$message.error("该帖子暂无评论");
        return;
      }
      this.$refs.commentModal.showModal(record);
    },
    openPostModal(record) {
      this.$refs.modifyPostModal.showModal(record.postId, 'view')
    },

    expand(e, record) {
      this.showExpand = e;
      if (!e) {
        this.expandedRowKeys = this.expandedRowKeys.filter(
            (v) => v != record.id
        );
        return;
      }
      // 必须延迟一下，否则读取不到expanTab组件
      if (record.childComments != 0) {
        this.expandedRowKeys.push(record.id);
      }
      if (e && record.childComments == 0) {
        this.$message.error("无回复评论！！！");
        return;
      }
      setTimeout(() => {
        this.$refs.expanTab.showExpanTab(record);
      }, 100);
    },
    changePrizeCount(record) {
      const that = this;

      MessageBox.prompt('请输入点赞调整数量（-表示减少，其他表示增加）', '调整点赞数量', {
        inputValue: '',
        inputPlaceholder: '输入调整数量',
      }).then(({value}) => {
        if (value) {
          that.showLoading = true;
          let prizeCount = (record.prizeCount || 0) + (+value);
          if (prizeCount < 0) {
            prizeCount = 0;
          }
          that.$postJson("comment/update", {id: record.id, prizeCount}).then(() => {
            that.showLoading = false;
            record.prizeCount = prizeCount;
            that.$message.success("点赞数修改成功");
            // this.search();
          });
        }
      })
    },
    editSuccess() {
      this.search();
    },
    modify(e, record) {
      this.$refs.modifyModal.showModal(record);
    },
    add() {
      this.$refs.modifyModal.showModal({type: 14}, 'new');
    },

    onDelete(record) {
      let msg = "您确定要删除【ID为" + record.id + "】的评论吗？";
      let data = [];
      if (Array.isArray(record)) {
        msg = "确定要删除所选评论吗？";
        data = record;
      } else {
        data = [record.id];
      }
      let that = this;
      this.$confirm({
        title: "删除确认",
        content: msg,
        centered: true,
        onOk() {
          that.$postJson("comment/delete", data).then(() => {
            that.$message.success("删除成功");
            that.search();
          });
        },
      });
    },
    modifyPrizeTask(record) {
      this.$refs.postPrizeTaskModal.showModal(record,'comment');
    },
    modifyCommentTask(record) {
      this.$refs.postCommentTaskModal.showModal(record,'comment');
    }
  },
};
</script>

<style lang="less" scoped>
.textClick {
  // color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.img-list {
  display: flex;
  justify-content: center;
}

.content {
  max-height: 100px;
  overflow-y: auto;
}
</style>
